import React, { useState } from "react";
import ExploreAtlas from "../assets/images/explore-atlas-min.webp";
import ExploreHome from "../assets/images/explore-home-min.webp";
import ExplorePlaceDetails from "../assets/images/explore-place-details-min.webp";

const Explore = () => {
    const [activeImage, setActiveImage] = useState("img2");
    return (
        <div className="flex flex-row justify-center items-center p-2 mt-24">
            <div
                data-aos="fade-left"
                data-aos-offset="40"
                data-aos-delay="200"
                className="cursor-pointer h-3/5 w-3/5 md:h-2/5 md:w-60 -mr-10"
                style={{
                    zIndex: activeImage === "img1" || activeImage == "img3" ? 1 : 0,
                    opacity: activeImage === "img1" || activeImage == "img3" ? 1 : 0.95,
                }}
                onClick={() => setActiveImage("img1")}
            >
                <img
                    src={ExploreAtlas}
                    idth="0px"
                    height="0px"
                    className="h-full w-full img"
                    alt="Explore Atlas"
                />
            </div>
            <div
                data-aos="fade"
                data-aos-delay="200"
                className="cursor-pointer h-3/5 w-4/5 md:h-2/5 md:w-72"
                style={{
                    zIndex: activeImage === "img2" ? 1 : 0,
                    opacity: activeImage === "img2" ? 1 : 0.95,
                }}
                onClick={() => setActiveImage("img2")}
            >
                <img
                    src={ExploreHome}
                    width="0px"
                    height="0px"
                    className="h-full w-full img"
                    alt="Explore"
                />
            </div>
            <div
                data-aos="fade-right"
                data-aos-offset="40"
                data-aos-delay="200"
                className="cursor-pointer h-3/5 w-3/5 md:h-2/5 md:w-60 -ml-10"
                style={{
                    zIndex: activeImage === "img1" || activeImage == "img3" ? 1 : 0,
                    opacity: activeImage === "img1" || activeImage == "img3" ? 1 : 0.95,
                }}
                onClick={() => setActiveImage("img3")}
            >
                <img
                    src={ExplorePlaceDetails}
                    idth="0px"
                    height="0px"
                    className="h-full w-full img"
                    alt="Explore Atlas"
                />
            </div>
        </div>
    );
};

export default Explore;
